import { JSX }	from "preact";

import { Dom }			from "ts-base/web/dom";

import { useMessages }	from "@geotoura/nav/useMessages";
import { Model }		from "@geotoura/nav/model";
import { DesktopList }	from "@geotoura/nav/DesktopList";
import * as actions		from "@geotoura/nav/actions";

export type DesktopProps	= Readonly<{
	model:	Model,
}>;

export const Desktop = ({ model }:DesktopProps):JSX.Element => {
	const msg = useMessages();

	return (
		<>
		<div class={Dom.classes(
				"nav-inner",
				model.screenSize
			)}>
			<div class="nav-menu">
				<div class="nav-menu-item nav-desktop-opener">
					<button class="nav-menu-item-link" onClick={() => actions.toggleMenu()}>
						<span>{msg.title}</span>
					</button>
					{ model.app !== null && <DesktopList data={model.app.data}/> }
				</div>
				{
					msg.featured.map(it =>
						<div class="nav-menu-item">
							<a class="nav-menu-item-link" href={it.href}><span>{it.text}</span></a>
						</div>
					)
				}
			</div>
			<a title={msg.mainTitle} href={msg.mainUrl} aria-label={msg.mainTitle}>
				<img class="nav-logo" src={`/img/common/${msg.logo}`} alt="Geotoura-Logo"/>
			</a>
		</div>
		</>
	);
};
