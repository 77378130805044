import { JSX }	from "preact";

import * as fbModel from "@geotoura/shared/fbModel";

import * as actions from "@geotoura/fb/actions";
import { Slider }	from "@geotoura/fb/components/Slider";

export type SliderlistProps = Readonly<{
	screen:	fbModel.ScreenOfSliderList,
	answer:	fbModel.AnswerOfSliderList,
}>;

export const Sliderlist = ({ screen, answer }:SliderlistProps):JSX.Element => {
	const setSlider = (returnValue:number, returnLabel:string):void => {
		const index:number = answer.content.sliderValues.findIndex(it => it.keySlider === returnLabel);

		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ASliderList>().atKey("sliderValues").atIndexRo(index).set({
				... answer.content.sliderValues[index],
				keySlider:		returnLabel,
				sliderValue:	returnValue,
				mailKey:		screen.content.sliders[index].mailKey,
				mailValue:		returnValue < fbModel.SliderValues.neutral ? screen.content.sliders[index].labelMin :
								returnValue > fbModel.SliderValues.neutral ? screen.content.sliders[index].labelMax :
								// TODO slider do we have to special case neutral like this?
								"",
			})
		);
	};

	return (
		<div class="Sliderlist Screen">
			<div class="Selectlist-title heading">{screen.content.title}</div>
			<ul>
				{
					screen.content.sliders.map((it, index) =>
						<li key={index}>
							<Slider slider={it} action={setSlider} value={answer.content.sliderValues[index].sliderValue}/>
						</li>
					)
				}
			</ul>
		</div>
	);
};
