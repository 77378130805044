import { JSX }	from "preact";

import { Dom }			from "ts-base/web/dom";

import { useMessages }	from "@geotoura/nav/useMessages";
import * as actions		from "@geotoura/nav/actions";
import { Model }		from "@geotoura/nav/model";
import { MobileList }	from "@geotoura/nav/MobileList";

export type MobileProps	= Readonly<{
	model:	Model,
}>;

export const Mobile = ({ model }:MobileProps):JSX.Element => {
	const msg = useMessages();

	return (
		<>
			<div class="nav-inner">
				<button class="textlink nav-toggle-button" onClick={() => actions.toggleMenu()}>
					<span>{msg.title}</span>
					<span class={Dom.classes(
						"fa-light",
						model.menu ? "fa-angle-down" : "fa-angle-right"
					)}></span>
				</button>
				<a title={msg.mainTitle} href={msg.mainUrl} aria-label={msg.mainTitle}>
					<img class="nav-logo" src={`/img/common/${msg.logo}`} alt="Geotoura-Logo"/>
				</a>
			</div>
			<div class={Dom.classes(
				"nav-mobile-area",
				model.menu && "open"
			)}>
				{ model.app !== null && model.menu &&
					<MobileList app={model.app}/>
				}
			</div>
		</>
	);
};
