import { JSX }				from "preact";
import { useState, useId }	from "preact/hooks";

import * as commonModel	from "@geotoura/shared/commonModel";
import * as fbModel		from "@geotoura/shared/fbModel";

import * as actions		from "@geotoura/fb/actions";
import { RadioButton }	from "@geotoura/fb/components/RadioButton";
import {
	Autocomplete,
	AutocompleteSuggestion,
}	from "@geotoura/fb/components/Autocomplete";

export type FlightProps	= Readonly<{
	screen:			fbModel.ScreenOfFlight,
	answer:			fbModel.AnswerOfFlight,
	isoCountries:	ReadonlyArray<commonModel.IsoCountry>,
}>;

export const Flight = ({ screen, answer, isoCountries }:FlightProps):JSX.Element => {
	// TODO fb make explicit in the db which radioitem relates to the open and which to the closed state
	const [ modalOpen,	setOpen ]	= useState(answer.content.valueRadio === "yes");
	const uid						= useId();

	const setRadio = (wasChecked:boolean, valueRadio:string, radioItemId:fbModel.RadioItemId):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.AFlight>().mod(it => ({
				...it,
				radioItemId:	wasChecked? null : radioItemId,
				valueRadio:		wasChecked? null : valueRadio,
				mailRadioValue:	!wasChecked && valueRadio !== ""
								?	screen.content.radioGroup.items.find(it => it.valueRadio === valueRadio)?.label ?? ""
								:	"",

			}))
		);

		// TODO fb make explicit in the db which radioitem relates to the open and which to the closed state
		if (!wasChecked && valueRadio === "yes")						setOpen(true);
		if (valueRadio === "no" || wasChecked && valueRadio === "yes")	setOpen(false);
	};

	const setCountry = (text:string):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.AFlight>().atKey("countryValue").set(text)
		);
	};

	const setPostcode = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.AFlight>().atKey("valuePostcode").set(ev.currentTarget.value)
		);
	};

	const countrySuggestions:ReadonlyArray<AutocompleteSuggestion>	=
		isoCountries.map(it => ({
			id:		it.code,
			text:	it.name,
		}));

	return (
		<div class="Flight Screen">
			<img class="Flight-icon" src={screen.content.icon}/>
			<div class="heading">{screen.content.radioGroup.title}</div>
			<div class={`RadioGroup ${screen.content.radioGroup.layout}`}>
				{ screen.content.radioGroup.items.map((item) =>
					<RadioButton
						key={item.radioItemId}
						checked={answer.content.valueRadio}
						item={item}
						action={setRadio}
					/>
				)}
			</div>
			{
				modalOpen &&
				<div>
					<div class="heading">{screen.content.modalTitle}</div>
					<Autocomplete
						label={screen.content.labelCountry}
						suggestions={countrySuggestions}
						value={answer.content.countryValue}
						action={setCountry}
					/>
					<div class="flex-r fb-field">
						<label class="fb-label" for={uid}>{screen.content.labelPostcode}</label>
						{/* NOTE type="tel" is a hack to switch to a number keyboard on the iphone as the is no suitable type for a zip code */}
						<input
							id={uid}
							class="fb-input"
							type="tel"
							value={answer.content.valuePostcode}
							onChange={setPostcode}
						/>
					</div>
				</div>
			}
		</div>
	);
};
