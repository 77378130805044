/* eslint-disable @typescript-eslint/no-explicit-any */

import { JSX }	from "preact";

import * as commonModel	from "@geotoura/shared/commonModel";
import * as fbModel		from "@geotoura/shared/fbModel";

import { FormValidity }	from "@geotoura/fb/model";
import { Radiostart }	from "@geotoura/fb/screenTypes/Radiostart";
import { Selectlist }	from "@geotoura/fb/screenTypes/Selectlist";
import { Date }			from "@geotoura/fb/screenTypes/Date";
import { Flight }		from "@geotoura/fb/screenTypes/Flight";
import { Hotel }		from "@geotoura/fb/screenTypes/Hotel";
import { Counterlist }	from "@geotoura/fb/screenTypes/Counterlist";
import { Sliderlist }	from "@geotoura/fb/screenTypes/Sliderlist";
import { Budget }		from "@geotoura/fb/screenTypes/Budget";
import { Decision }		from "@geotoura/fb/screenTypes/Decision";
import { Contact }		from "@geotoura/fb/screenTypes/Contact";
import { Text }			from "@geotoura/fb/screenTypes/Text";

export type ScreenDisplayProps<S extends fbModel.Screen>	= Readonly<{
	screen:			S,
	answer:			fbModel.AnswerForScreen<S>,
	traveldays:		number,
	isoCountries:	ReadonlyArray<commonModel.IsoCountry>,
	formValidity:	FormValidity,
	serverResponse:	string,
}>;

// TODO fbtype make this safe - it seems we can call this with "Any Screen" and then have to deal with "Any Answer"
export const ScreenDisplay = <S extends fbModel.Screen>({ screen, answer, traveldays, isoCountries, formValidity, serverResponse }:ScreenDisplayProps<S>):JSX.Element =>
	<div class="ScreenDisplay">
		{ getScreen<S>(screen, answer, traveldays, isoCountries, formValidity, serverResponse) }
	</div>;

// TODO fbtype make this work without casts
const getScreen =<S extends fbModel.Screen>(screen:S, answer:fbModel.AnswerForScreen<S>, traveldays:number, isoCountries:ReadonlyArray<commonModel.IsoCountry>, formValidity:FormValidity, serverResponse:string) => {
	switch (screen.stId) {
		case "RADIOSTART":	return <Radiostart	screen={screen}	answer={answer as any}/>;
		case "SELECTLIST":	return <Selectlist	screen={screen}	answer={answer as any}/>;
		case "DATE":		return <Date		screen={screen}	answer={answer as any}/>;
		case "FLIGHT":		return <Flight		screen={screen}	answer={answer as any} isoCountries={isoCountries}/>;
		case "HOTEL":		return <Hotel		screen={screen}	answer={answer as any}/>;
		case "COUNTERLIST":	return <Counterlist	screen={screen}	answer={answer as any}/>;
		case "SLIDERLIST":	return <Sliderlist	screen={screen}	answer={answer as any}/>;
		case "BUDGET":		return <Budget		screen={screen}	answer={answer as any} traveldays={traveldays}/>;
		case "DECISION":	return <Decision	screen={screen}	answer={answer as any}/>;
		case "CONTACT":		return <Contact		screen={screen}	answer={answer as any} formValidity={formValidity}/>;
		case "TEXT":		return <Text		screen={screen}	serverResponse={serverResponse}/>;
	}
};
