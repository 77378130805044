import { JSX }									from "preact";
import { useState, useId, useRef, useEffect }	from "preact/hooks";

import { Arrays }		from "ts-base/arrays";
import { Dom }			from "ts-base/web/dom";

import * as searchText	from "@geotoura/common/util/searchText";

export type AutocompleteProps	= Readonly<{
	label:			string,
	suggestions:	ReadonlyArray<AutocompleteSuggestion>,
	value:			string,
	action:			(it:string)=>void,
}>;

export type AutocompleteSuggestion	= {
	// uniquely identifies an item in the suggestions list, regardless of filtering
	id:		AutocompleteSuggestionId,
	text:	string,
};

export type AutocompleteSuggestionId	= string|number|symbol;

export const Autocomplete = ({ label, suggestions, value, action }:AutocompleteProps):JSX.Element => {
	const uid							= useId();
	const inputRef						= useRef<HTMLInputElement|null>(null);
	const listRef						= useRef<HTMLUListElement|null>(null);
	const [ hasFocus,	setHasFocus ]	= useState(false);
	const [ isTyping,	setTyping ]		= useState(false);
	const [ selectedId,	setSelectedId ]	= useState<AutocompleteSuggestionId|null>(null);

	//-----------------------------------------------------------------------------

	// ensure the selection list does not grow so tall it goes out of the window
	useEffect(
		() => {
			const list	= listRef.current;
			if (list === null)	return;

			/*
			prevent the suggestion list from growing below the bottom edge of the screen

			@see nav.less where .App.fullscreen .Nav uses this exact fixed height

			NOTE in css this is only applied for mobile, but according to elian that will not cause problems on the desktop
			NOTE this breaks when position.top or window.innerHeight change behind our backs
			*/
			const mobileNavHeight	= 78;
			const position			= list.getBoundingClientRect();
			const maxHeight			= Math.floor(window.innerHeight - mobileNavHeight - position.top);
			list.style.maxHeight	= `${maxHeight}px`;
		},
		[]
	);

	//-----------------------------------------------------------------------------

	const currentSuggestions:ReadonlyArray<AutocompleteSuggestion>	=
		suggestions.filter(it => searchText.includes(it.text, value));

	const usefulSearch	= value.trim().length !== 0;

	const displayChoices	=
		hasFocus && isTyping && usefulSearch && currentSuggestions.length !== 0;

	const selectedSuggestion:AutocompleteSuggestion|null	=
		currentSuggestions.find(it => it.id === selectedId) ?? null;

	const firstSuggestion	= ():AutocompleteSuggestion|null	=>
		Arrays.headOrNull(currentSuggestions);

	const lastSuggestion	= ():AutocompleteSuggestion|null	=>
		Arrays.lastOrNull(currentSuggestions);

	// TODO auto find in all suggestions, but choose a good one?

	const prevSelection	= ():AutocompleteSuggestion|null	=> {
		if (selectedSuggestion === null)	return null;
		const index		= currentSuggestions.findLastIndex(it => it.id === selectedSuggestion.id);
		if (index === -1)					return null;
		const item	= currentSuggestions[index-1];
		if (item === undefined)				return null;
		return item;
	};

	const nextSelection	= ():AutocompleteSuggestion|null	=> {
		if (selectedSuggestion === null)	return null;
		const index		= currentSuggestions.findIndex(it => it.id === selectedSuggestion.id);
		if (index === -1)					return null;
		const itwm	= currentSuggestions[index+1];
		if (itwm === undefined)				return null;
		return itwm;
	};

	//-----------------------------------------------------------------------------

	// ensure the selected item is always visible
	useEffect(
		() => {
			if (selectedSuggestion === null)	return;

			const list	= listRef.current;
			if (list === null)	return;

			const item	= list.querySelector(`.Autocomplete-suggestionItem-${selectedSuggestion.id.toString()}`);
			if (item === null)	return;

			item.scrollIntoView({
				block:		"nearest",
				behavior:	"smooth",
			});
		},
		// runs on all updates
		// [],
	);

	//-----------------------------------------------------------------------------

	const handleFocusEvent = ():void => {
		setHasFocus(true);
	};

	const handleBlurEvent = ():void => {
		// without the setTimeout, we hide the popup here before handleClick can act
		window.setTimeout(() => setHasFocus(false), 300);
	};

	const handleInput = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		setTyping(true);
		action(ev.currentTarget.value);
	};

	const clearSearch = ():void => {
		setTyping(false);
		setSelectedId(null);
		action("");
		inputRef.current?.focus();
	};

	const handleClick = (suggestion:AutocompleteSuggestion):void => {
		setTyping(false);
		setSelectedId(null);
		action(suggestion.text);
	};

	const handleKeypress = (ev:JSX.TargetedKeyboardEvent<HTMLInputElement>):void => {
		switch (ev.key) {
			case "Enter": {
				setTyping(false);
				setSelectedId(null);

				if (selectedSuggestion !== null) {
					action(selectedSuggestion.text);
				}
				else {
					// if the user wanted something not in the list, who are we to deny her?
					action(ev.currentTarget.value);
				}
				break;
			}
			case "Escape": {
				setTyping(false);
				setSelectedId(null);
				break;
			}
			case "ArrowUp": {
				// do not move the cursor in the input field
				ev.preventDefault();

				const choice	= prevSelection() ?? lastSuggestion();
				if (choice !== null) {
					setSelectedId(choice.id);
				}
				break;
			}
			case "ArrowDown": {
				// do not move the cursor in the input field
				ev.preventDefault();

				const choice	= nextSelection() ?? firstSuggestion();
				if (choice !== null) {
					setSelectedId(choice.id);
				}
				break;
			}
		}
	};

	//-----------------------------------------------------------------------------

	return (
		<div class="Autocomplete fb-field flex-r">
			<label for={uid} class="fb-label">{label}</label>
			<div class="Autocomplete-input-wrapper">
				{/* TODO wrap input and buttons in a separate div */}
				<input
					ref={inputRef}
					value={value}
					type="text"
					id={uid}
					class="Autocomplete-input fb-input"
					autocomplete="off"
					onFocus={handleFocusEvent}
					onBlur={handleBlurEvent}
					onInput={handleInput}
					onKeyDown={handleKeypress}
				/>
				{	value.length === 0
					?	<div class="Autocomplete-button Autocomplete-search">
							<span class="fa-light fa-search"></span>
						</div>
					:	<button onClick={clearSearch} class="Autocomplete-button Autocomplete-clear">
							<span class="fa-light fa-close"></span>
							<span class="sr-only">Clear Field</span>
						</button>
				}
				<ul
					ref={listRef}
					class={Dom.classes(
						"Autocomplete-suggestionList",
						displayChoices ? "open" : "closed"
					)}
					role="listbox"
					aria-labelledby={uid}
				>
					{ currentSuggestions.map((suggestion) => {
						const selected	= suggestion.id === selectedId;

						return (
							<li
								key={suggestion.id}
								class={Dom.classes(
									"Autocomplete-suggestionItem",
									`Autocomplete-suggestionItem-${suggestion.id.toString()}`,
									selected && "active"

								)}
								aria-selected={selected}
								role="option"
								onClick={() => handleClick(suggestion)}
							>
								{suggestion.text}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
